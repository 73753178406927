html,
body,
#__next,
[data-nextjs-scroll-focus-boundary] {
  height: 100%;
}
body:first-child {
  height: 100%;
}

[data-nextjs-scroll-focus-boundary] {
  display: contents;
}
